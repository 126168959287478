import React from "react";
import Layout from "../components/layout";
import { useStaticQuery, graphql } from "gatsby";
import SEO from "../components/seo";

const TermsOfService = () => {
  const {
    Terms: {
      frontmatter: { title, last_updated },
      html,
    },
  } = useStaticQuery(graphql`
    query Terms {
      Terms: markdownRemark(fileAbsolutePath: { regex: "/terms-of-service/" }) {
        frontmatter {
          title
          last_updated
        }
        html
      }
    }
  `);
  return (
    <Layout>
      <SEO
        seo={{
          title: title,
          description:
            "The following terms of service apply to all website development/design services provided by Merlin Panel to you, our client.",
          previewURL: "https://merlinpanel.com/images/terms/preview.png",
        }}
      />
      <div className="max-w-6xl mx-auto mt-20 terms-main px-4">
        <div className="py-16">
          <h2>{title}</h2>
          <h4 className="mt-4">{last_updated}</h4>
          <div
            className="mt-4"
            dangerouslySetInnerHTML={{ __html: html }}
          ></div>
        </div>
      </div>
    </Layout>
  );
};

export default TermsOfService;
